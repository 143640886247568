<template>
  <div>
    <Card class="shadow-none">
      <template v-slot:title>
        <h4>Coberturas:</h4>
      </template>
      <template v-slot:subtitle> </template>
      <template v-slot:content>

        <div class="p-grid">
          <div class="p-col-4">

            <div class="p-grid">
              <div class="p-col-12">
                <h5 class="p-mb-0">Tempo do contrato (meses)</h5>
              </div>

              <div class="p-field p-col-12">
                <InputNumber
                  id="meses"
                  v-model="meses"
                  style="width: 224px"
                  :class="
                    fieldsError.some((v) => v === 'meses')
                      ? 'p-invalid'
                      : ''
                  "
                  @focus="$utils.inputNumberFocus"
                />
              </div>
            </div>

          </div>
          <div class="p-col-8">

            <div class="p-grid">
              <div class="p-col-12">
                <h5 class="p-mb-0">Tipo de Contração</h5>
              </div>

              <div class="p-field p-col-12">
                <SelectButton
                  id="novo"
                  v-model="novo"
                  :options="novos"
                  optionLabel="label"
                  optionValue="value"
                  :class="
                    fieldsError.some((v) => v === 'novo')
                      ? 'p-invalid'
                      : ''
                  "
                />
              </div>

            </div>

          </div>

        </div>

        <div class="p-grid">
          <div class="p-col-12">
            <h5>Valores da Locação</h5>
          </div>

          <div class="p-col-6 p-md-2 p-field">
            <label for="valor_aluguel">Aluguel</label>
            <InputNumber
              id="valor_aluguel"
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              v-model="valor_aluguel"
              :class="
                fieldsError.some((v) => v === 'valor_aluguel')
                  ? 'p-invalid'
                  : ''
              "
              @focus="$utils.inputNumberFocus"
            />
          </div>
          <div class="p-col-6 p-md-2 p-field">
            <label for="valor_luz">Luz</label>
            <InputNumber
              id="valor_luz"
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              v-model="valor_luz"
              :class="
                fieldsError.some((v) => v === 'valor_luz') ? 'p-invalid' : ''
              "
              @focus="$utils.inputNumberFocus"
            />
          </div>
          <div class="p-col-6 p-md-2 p-field">
            <label for="valor_agua">Água</label>
            <InputNumber
              id="valor_agua"
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              v-model="valor_agua"
              :class="
                fieldsError.some((v) => v === 'valor_agua') ? 'p-invalid' : ''
              "
              @focus="$utils.inputNumberFocus"
            />
          </div>
          <div class="p-col-6 p-md-2 p-field">
            <label for="valor_gas">Gás</label>
            <InputNumber
              id="valor_gas"
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              v-model="valor_gas"
              :class="
                fieldsError.some((v) => v === 'valor_gas') ? 'p-invalid' : ''
              "
              @focus="$utils.inputNumberFocus"
            />
          </div>
          <div class="p-col-6 p-md-2 p-field">
            <label for="valor_condominio">Condomínio</label>
            <InputNumber
              id="valor_condominio"
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              v-model="valor_condominio"
              :class="
                fieldsError.some((v) => v === 'valor_condominio')
                  ? 'p-invalid'
                  : ''
              "
              @focus="$utils.inputNumberFocus"
            />
          </div>
          <div class="p-col-6 p-md-2 p-field">
            <label for="valor_iptu">IPTU</label>
            <InputNumber
              id="valor_iptu"
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              v-model="valor_iptu"
              :class="
                fieldsError.some((v) => v === 'valor_iptu') ? 'p-invalid' : ''
              "
              @focus="$utils.inputNumberFocus"
            />
          </div>

          <!--

          <div class="p-col-12">
            <h5>Coberturas adicionais <Button label="todas" class="p-button-text p-py-0" @click="todasCoberturas" /></h5>
          </div>

          <div class="p-field p-col-6 p-md-2">
            <div class="p-field-checkbox">
              <Checkbox
                id="cobertura_multas_contratuais"
                v-model="cobertura_multas_contratuais"
                :binary="true"
                :class="
                  fieldsError.some((v) => v === 'cobertura_multas_contratuais')
                    ? 'p-invalid'
                    : ''
                "
              />
              <label for="cobertura_multas_contratuais">Multas Contratuais</label>
            </div>
          </div>

          <div class="p-field p-col-6 p-md-2">
            <div class="p-field-checkbox">
              <Checkbox
                id="cobertura_pintura_externa"
                v-model="cobertura_pintura_externa"
                :binary="true"
                :class="
                  fieldsError.some((v) => v === 'cobertura_pintura_externa')
                    ? 'p-invalid'
                    : ''
                "
              />
              <label for="cobertura_pintura_externa">Pintura Externa</label>
            </div>
          </div>

          <div class="p-field p-col-6 p-md-2">
            <div class="p-field-checkbox">
              <Checkbox
                id="cobertura_pintura_interna"
                v-model="cobertura_pintura_interna"
                :binary="true"
                :class="
                  fieldsError.some((v) => v === 'cobertura_pintura_interna')
                    ? 'p-invalid'
                    : ''
                "
              />
              <label for="cobertura_pintura_interna">Pintura Interna</label>
            </div>
          </div>

          <div class="p-field p-col-6 p-md-2">
            <div class="p-field-checkbox">
              <Checkbox
                id="cobertura_danos_imovel"
                v-model="cobertura_danos_imovel"
                :binary="true"
                :class="
                  fieldsError.some((v) => v === 'cobertura_danos_imovel')
                    ? 'p-invalid'
                    : ''
                "
              />
              <label for="cobertura_danos_imovel">Danos ao Imóvel</label>
            </div>
          </div>
          -->
        </div>
      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button label="Voltar" @click="prevPage()" icon="pi pi-angle-left" />
          <Button
            label="Próximo"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    errorData: Array,
    processo: Object,
    imobiliaria: Number
  },
  emits: [
    "prev-page",
    "next-page",
    "complete"
  ],
  data() {
    return {
      fieldsError: [],

      valor_aluguel: 0,
      valor_luz: 0,
      valor_agua: 0,
      valor_gas: 0,
      valor_condominio: 0,
      valor_iptu: 0,
      cobertura_multas_contratuais: true,
      cobertura_pintura_externa: true,
      cobertura_pintura_interna: true,
      cobertura_danos_imovel: true,
      meses: 0,
      novo: 1,
      
      novos: [{value: 1, label: "Nova Contratação"},{value: 0, label: "Mudança de Garantia"}],

    };
  },
  mounted() {

    this.fieldsError = this.errorData;

    if(this.processo != null) {

      const p = this.processo;

      this.valor_aluguel = parseFloat(p.valor_aluguel);
      this.valor_luz = parseFloat(p.valor_luz);
      this.valor_agua = parseFloat(p.valor_agua);
      this.valor_gas = parseFloat(p.valor_gas);
      this.valor_condominio = parseFloat(p.valor_condominio);
      this.valor_iptu = parseFloat(p.valor_iptu);
      this.cobertura_multas_contratuais = p.cobertura_multas_contratuais == 1;
      this.cobertura_pintura_externa = p.cobertura_pintura_externa == 1;
      this.cobertura_pintura_interna = p.cobertura_pintura_interna == 1;
      this.cobertura_danos_imovel = p.cobertura_danos_imovel == 1;
      this.meses = p.meses;
      this.novo = p.novo;

    }

  },
  watch: {
    
    errorData: function() {
      this.fieldsError = this.errorData;
    },

  },
  methods: {
    nextPage() {
      if (this.validateForm()) {
        let formData = {
          valor_aluguel: this.valor_aluguel,
          valor_luz: this.valor_luz,
          valor_agua: this.valor_agua,
          valor_gas: this.valor_gas,
          valor_condominio: this.valor_condominio,
          valor_iptu: this.valor_iptu,
          cobertura_multas_contratuais: this.cobertura_multas_contratuais,
          cobertura_pintura_externa: this.cobertura_pintura_externa,
          cobertura_pintura_interna: this.cobertura_pintura_interna,
          cobertura_danos_imovel: this.cobertura_danos_imovel,
          meses: this.meses,
          novo: this.novo
        };

        this.$emit("next-page", { formData: formData, pageIndex: 2 });
      }
    },
    prevPage() {
      this.$emit("prev-page", { pageIndex: 2 });
    },
    validateForm() {
      this.fieldsError = [];

      if (this.meses <= 0)
        this.fieldsError.push("meses");
      
      if(!this.valor_aluguel)
        this.fieldsError.push("valor_aluguel");

      if(this.fieldsError.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail:
            "Ao menos a periodicidade e o aluguel precisam ser preenchidos!",
          life: 5000,
        });

        return false;
      }

      return true;
    },

    todasCoberturas() {
      this.cobertura_multas_contratuais = true;
      this.cobertura_pintura_externa = true;
      this.cobertura_pintura_interna = true;
      this.cobertura_danos_imovel = true;
      return false;
    }
  },
};
</script>
<style scoped lang="scss">
::v-deep(.p-inputtext) {
  width: 100%;
}

::v-deep(.p-field > label) {
    display: block;
}

</style>